.card {
  background: white;
  border: 1px $silver solid;
  width: 100%;
  margin: auto;
  min-height: 100%;
  max-height: 100%;
  padding: 2%;
  flex-shrink: 0;
  position: relative;
  left: 0;
  right: 0;
  &__no-background {
    background: transparent;
    border: none; } }
