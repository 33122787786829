/*----------------------  2 [Base]  ---------------------- */
  /**
    * Base syntax should not contain any classes, id or use attribute selectors of any kind.
    * Resets or basic styles for elements belong here
    *
    * Example:
    *
    * header { ... }
    *
    * */
* {
  box-sizing: border-box;
  &:before, &:after {
    box-sizing: inherit; } }
html {
  font-size: 100%;
  height: 100vh;
  width: 100vw; }
body {
  background: $primary-dark;
  color: $gray;
  font: 1rem/1 sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  overflow: hidden; }
main {
  // border: 1px solid red
  height: 70%;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: none;
  position: relative;
  top: -10%;
  overflow: hidden; }
h1 {
  color: $white;
  font: $weightBold $header $roboto; }
button {
  background: none;
  border: none;
  padding: 0;
  margin: 0; }
#resources {
  background: $white;
  li {
    font-size: 1.2rem;
    color: $primary;
    &:hover {
      color: darken($primary, 5%); }
    a {
      text-decoration: none; } } }

/*----------------------  End Base  ---------------------- */
