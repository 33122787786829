// Variables
$primary: #633AC0;
$primary-dark: #4B4060;
$white: #FFF;
$silver: #E2E2E2;
$gray: #707070;

// Fonts Family
$roboto: 'Roboto', sans-serif;
$robotoMono: 'Roboto Mono', monospace;
// Font Size
$normal: 1.75rem;
$subHeader: 3.5rem;
$header: 10rem;
// Font weight
$weightLight: 300;
$weightNormal: 400;
$weightMedium: 500;
$weightBold: 700;
