.nav {
  &--dots {
    span {
      @include size(20px);
      font-size: 0;
      border: 6px $white solid;
      display: inline-block;
      border-radius: 50%;
      transition: 100ms;
      &:hover, &.is-active {
        background: $white; }
      + span {
        margin-left: 6%; } } }
  &--buttons {
    display: flex;
    width: 100%; } }
