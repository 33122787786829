$count: 7;

@for $i from  1 through 7 {
  #page-#{$i} {
    left: 200%;
    top: 196%;
    // left: unquote(( 278 + $i) + 'rem')
    left: 0;
    position: absolute; } }

#welcome, #resources {
  left: 200%;
  top: 196%;
  // left: unquote(( 278 + $i) + 'rem')
  left: 0;
  position: absolute; }
