/*----------------------  3 [Layout]  ---------------------- */
  /**
    *
    * Layout Syntax Example:
    *
    * .l-container { ... }
    *
    * Sass Syntax
    *
    * .l-
    *   &container
    *     ...
    *
    * <section class="l-container"> ... </section>
    *
    *
    * */
.l- {
  &page--container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    position: relative; }
  &nav--container {
      display: flex;
      width: 85%;
      position: fixed;
      bottom: 0;
      flex-direction: column;
      justify-content: space-around;
      height: 25%; } }
.screen {
  &--start {
    h1 {
      display: flex;
      flex-direction: column;
      padding: 0;
      span {
        display: block;
        font-size: $subHeader;
        font-weight: $weightLight;
        margin: 0;
        padding: 0; } } } }
/*----------------------  End Layout  ---------------------- */
